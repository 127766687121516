// @flow
import { type JSSThemeDefaultPopularLeaguesType } from '../JSSThemeDefaultPopularLeagues';

export const JSSThemePopularLeagues61000: JSSThemeDefaultPopularLeaguesType = themeVars => {
  const {
    colors: {
      colorTypes: { background1, text3 },
    },
    fontSizes,
  } = themeVars;
  return {
    PopularLeagues: {
      root: {
        background: background1,
        '@media (pointer: coarse)': {
          background: text3,
        },
      },
      header: {
        fontSize: fontSizes.xxl,
      },
    },
  };
};
