// @flow
import { type JSSThemeDefaultMyBetsType } from '../JSSThemeDefaultMyBets';

export const JSSThemeMyBets61000: JSSThemeDefaultMyBetsType = themeVars => {
  const {
    colors: {
      colorCombinations: { whiteBg1, whiteNeutral1 },
      colorTypes: { white, neutral1, black, primary, neutral3 },
    },
  } = themeVars;

  return {
    ConfirmationBox: {
      text: {
        color: white,
        fontWeight: 'normal',
      },
      confirmation: {
        background: neutral1,
      },
      loading: whiteNeutral1,
    },
    CashOut: {
      success: whiteNeutral1,
    },
    MyBets: {
      content: {
        ...whiteBg1,
      },
      myBetsTabsItem: {
        background: neutral3,
        color: black,
        '&:hover, &.active': {
          background: primary,
          color: black,
        },
      },
    },
  };
};
