// @flow
import { type JSSThemeDefaultBetgeniusType } from '../JSSThemeDefaultBetgenius';

export const JSSThemeBetgenius61000: JSSThemeDefaultBetgeniusType = themeVars => {
  const {
    colors: {
      colorTypes: { black },
    },
  } = themeVars;
  return {
    iframeWrapper: {
      background: black,
    },
  };
};
