// @flow
import { type JSSThemeDefaultAuthFormType } from '../JSSThemeDefaultAuthForm';

export const JSSThemeAuthForm61000: JSSThemeDefaultAuthFormType = themeVars => {
  const {
    margins,
    borderRadius,
    colors: {
      colorTypes: { primary, black },
    },
  } = themeVars;
  return {
    AuthFormDesktop: {
      depositWrap: {
        padding: [margins.xs, margins.md],
        background: primary,
        borderRadius: borderRadius.md,
        marginRight: margins.xs,
      },
    },
    DepositButton: {
      link: {
        color: black,
        '&:hover': {
          color: black,
          opacity: 0.7,
        },
      },
    },
  };
};
