// @flow
import {
  type JSSThemeShapeType,
  type JSSThemeVariablesShapeType,
  type ColorTypes,
  getColorCombinations,
  type GetJSSThemePatternsType,
  colorTypes,
} from '../JSSThemeDefault';
import { applyOnlyDesktop, getButtonColors } from '../jss-helpers';
import { JSSThemeBetslip as betslip } from './JSSThemeBetslip61000';
import { JSSThemeHeaderMenu61000 } from './JSSThemeHeaderMenu61000';
import { JSSThemeMyBets61000 } from './JSSThemeMyBets61000';
import { JSSThemeBannerItems61000 } from './JSSThemeBannerItems61000';
import { JSSThemeSportsMenu61000 } from './JSSThemeSportsMenu61000';
import { JSSThemeRegistration61000 } from './JSSThemeRegistration61000';
import { JSSThemeForm61000 } from './JSSThemeForm61000';
import { JSSThemeFooterAll61000 } from './JSSThemeFooterAll61000';
import { JSSThemeTooltip61000 } from './JSSThemeTooltip61000';
import { JSSThemeBonuses61000 } from './JSSThemeBonuses61000';
import { JSSThemeHeaderMobile61000 } from './JSSThemeHeaderMobile61000';
import { JSSThemeSearch61000 } from './JSSThemeSearch61000';
import { JSSThemeEventSlider61000 } from './JSSThemeEventSlider61000';
import { JSSThemeOutcomes61000 } from './JSSThemeOutcomes61000';
import { JSSThemeCoupons61000 } from './JSSThemeCoupons61000';
import { JSSThemeHeader61000 } from './JSSThemeHeader61000';
import { JSSThemeBalance61000 } from './JSSThemeBalance61000';
import { JSSThemeAuthForm61000 } from './JSSThemeAuthForm61000';
import { JSSThemePopularLeagues61000 } from './JSSThemePopularLeagues61000';
import { JSSThemeAccountSection61000 } from './JSSThemeAccountSection61000';
import { JSSTheme61000Resets } from './JSSTheme61000Resets';
import { JSSThemeLive61000 } from './JSSThemeLive61000';
import { JSSThemeCasino61000 } from './JSSThemeCasino61000';
import { JSSThemePromotions61000 } from './JSSThemePromotions61000';
import { JSSThemeLanguageSwitcher61000 } from './JSSThemeLanguageSwitcher61000';
import { JSSThemeSitemap61000 } from './JSSThemeSitemap61000';
import { JSSThemeBetgenius61000 } from './JSSThemeBetgenius61000';

export const colorTypes61000: ColorTypes = {
  ...colorTypes,
  primary: '#FFBA00',
  text1: '#fff',
  text2: '#fff',
  text3: '#000000',
  text4: 'rgba(255, 255, 255, 0.5)',
  text5: '',
  background1: '#000000',
  background2: '#1e4e07',
  background3: '#000000',
  background4: '#999',
  background5: '#000',
  background6: '#000', //new color
  shadow: 'rgba(0, 0, 0, 0.4)',
  accent: '#FFBA00',
  success: '#21a22d',
  error: '#ff3434',
  error2: '#ff3434',
  error3: '#ff3434',
  warning: 'orange',
  inform: '#F00D1A',
  neutral1: '#000',
  neutral2: '#5c0101',
  neutral3: '#ebebeb',
  neutral4: '#1f3d63', //available for a new color
  border1: '#545e66',
  border2: '#1c1c1c',
};

const pick = ({
  primaryWhite,
  whitePrimary,
  text2Primary,
  whiteBg1,
  whiteBg2,
  whiteBg3,
  whiteSuccess,
  whiteAccent,
  whiteInform,
  whiteNeutral2,
  text2Neutral1,
  text2Bg2,
  whiteError,
  whiteNeutral1,
  text1Bg4,
  primaryBg1,
  text3White,
  text2Bg3,
  whiteText3,
}) => ({
  primaryWhite,
  whitePrimary,
  text2Primary,
  whiteBg1,
  whiteBg2,
  whiteBg3,
  whiteSuccess,
  whiteAccent,
  whiteInform,
  whiteNeutral2,
  text2Neutral1,
  text2Bg2,
  whiteError,
  whiteNeutral1,
  text1Bg4,
  primaryBg1,
  text3White,
  text2Bg3,
  whiteText3,
});

export const colorCombinations61000 = getColorCombinations(
  colorTypes61000,
  pick
);

export const JSSThemeVariables61000: JSSThemeVariablesShapeType = {
  colors: {
    colorTypes: colorTypes61000,
    colorCombinations: colorCombinations61000,
  },
  fonts: {
    default: {
      name: 'osg-ubuntu',
      fontFamily: 'osg-ubuntu, Helvetica Neue, Helvetica, Arial, sans-serif',
      config: [
        {
          path: 'ubuntu/ubuntu-regular-webfont',
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
        {
          path: 'ubuntu/ubuntu-bold-webfont',
          fontWeight: 'bold',
          fontStyle: 'normal',
        },
      ],
    },
  },
};

export const JSSTheme61000: JSSThemeShapeType = (themeVars) => {
  const {
    colors: {
      colorCombinations: { whiteBg1, whiteNeutral1, text4 },
      colorTypes: {
        background1,
        background3,
        background4,
        primary,
        text2,
        text1,
        black,
        white,
        border2,
      },
    },
    fontSizes,
    margins,
    borderRadius,
  } = themeVars;
  return {
    Heading: {
      common: {
        color: text4,
        padding: [margins.md, margins.xm, '0'],
      },
    },

    Geoblock: {
      logoGeoblock: {
        background: background1,
      },
      textGeoblock: {
        color: white,
      },
    },
    mybets: JSSThemeMyBets61000(themeVars),
    header: JSSThemeHeader61000(themeVars),
    headerMenu: JSSThemeHeaderMenu61000(themeVars),
    Button: {
      root: {
        lineHeight: '26px',
      },
      default: getButtonColors(whiteNeutral1),
      primary: {
        color: black,
        '&:hover': {
          color: black,
        },
      },
      block: {
        width: '100%',
      },
      secondary: {
        color: black,
        background: background4,
        '&:hover': {
          color: black,
          background: primary,
        },
      },
      accent: {
        background: primary,
        color: black,
        '&:hover': {
          color: black,
        },
      },
      large: {
        lineHeight: '42px',
        fontSize: fontSizes.xl,
      },
      small: {
        padding: [margins.xs, margins.md],
      },
      linkSecondary: {
        color: text2,
        ...applyOnlyDesktop({
          '&:hover': {
            color: primary,
          },
        }),
      },
      linkAccent: {
        color: primary,
        ...applyOnlyDesktop({
          '&:hover': {
            color: text1,
          },
        }),
      },
    },

    betslip: betslip(themeVars),
    LiveCalendar: {
      h2: {
        ...whiteBg1,
        fontWeight: 'bold',
        padding: [0, margins.md],
      },
      dateTitle: {
        background: background3,
        borderBottom: `1px solid ${background1}`,
        padding: [margins.sm, margins.md],
      },
      dateEvent: {
        background: background1,
        borderBottom: `1px solid ${background3}`,
        padding: [margins.sm, margins.md],
        '&:hover': {
          background: '#363636',
        },
      },
    },
    IconCircle: {
      root: {
        background: primary,
      },
    },
    NoBetsMessage: {
      root: {
        background: background1,
      },
    },
    BetslipMyBetsPanelTitle: {
      heading: {
        boxShadow: `inset 0px -1px ${black}`,
        background: 'transparent',
        color: white,
      },
      title: {
        lineHeight: `${margins.xl}px`,
        fontWeight: 'normal',
      },
    },

    BetslipMyBetsStakePotentialTotalUI: {
      stakeTitle: {
        fontWeight: 'normal',
        marginBottom: margins.md,
        marginTop: 0,
        fontSize: fontSizes.sm,
      },
      money: {
        fontSize: fontSizes.md,
        color: black,
      },
      stakePotentialWrapper: {
        marginTop: margins.xs,
        marginBottom: margins.sm,
        background: white,
        padding: [margins.xs, margins.md],
        borderTop: `none`,
        borderRadius: borderRadius.md,
      },
      stakePotential: {
        color: black,
      },
    },
    BetslipMyBetsPanelUI: {
      panel: {
        background: '#EBEBEB',
        fontSize: fontSizes.sm,
        margin: 0,
        marginBottom: 5,
      },
    },
    banners: JSSThemeBannerItems61000(themeVars),
    sportsMenu: JSSThemeSportsMenu61000(themeVars),
    registration: JSSThemeRegistration61000(themeVars),
    form: JSSThemeForm61000(themeVars),
    footerAll: JSSThemeFooterAll61000(themeVars),
    tooltip: JSSThemeTooltip61000(themeVars),
    bonuses: JSSThemeBonuses61000(themeVars),
    headerMobile: JSSThemeHeaderMobile61000(themeVars),
    search: JSSThemeSearch61000(themeVars),
    eventSlider: JSSThemeEventSlider61000(themeVars),
    outcomes: JSSThemeOutcomes61000(themeVars),
    coupons: JSSThemeCoupons61000(themeVars),
    balance: JSSThemeBalance61000(themeVars),
    live: JSSThemeLive61000(themeVars),
    authForm: JSSThemeAuthForm61000(themeVars),
    popularLeagues: JSSThemePopularLeagues61000(themeVars),
    accountSection: JSSThemeAccountSection61000(themeVars),
    languageSwitcher: JSSThemeLanguageSwitcher61000(themeVars),
    promotions: JSSThemePromotions61000(themeVars),
    sitemap: JSSThemeSitemap61000(themeVars),
    resets: JSSTheme61000Resets(themeVars),
    casino: JSSThemeCasino61000(themeVars),
    betgenius: JSSThemeBetgenius61000(themeVars),
    OfflineMessage: {
      popup: {
        background: border2,
      },
    },
    PageNotFound: {
      pageNotFoundRoot: {
        color: white,
        background: '#1c1c1c',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
      },
    },
  };
};

export const getJSSThemePatterns61000: GetJSSThemePatternsType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { black, primary },
      colorCombinations: { text2Bg2, whiteAccent },
    },
    fontSizes,
    margins,
  } = themeVars;
  return {
    buttons: {
      primary: getButtonColors(whiteAccent),
      secondary: getButtonColors(text2Bg2),
      accent: {
        background: primary,
        color: black,
      },
      large: {
        lineHeight: '42px',
        fontSize: fontSizes.xl,
      },
      small: {
        padding: [margins.xs, margins.md],
      },
    },
  };
};
