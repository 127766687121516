// @flow
import { type JSSThemeDefaultCouponsType } from '../JSSThemeDefaultCoupons';

export const JSSThemeCoupons61000: JSSThemeDefaultCouponsType = (themeVars) => {
  const {
    colors: {
      colorTypes: { black, background1, text4, primary },
    },
    margins,
  } = themeVars;
  return {
    CouponHeaderComponent: {
      headerEventsCount: {
        background: `${text4} !important`,
      },
    },
    MobileWidgetCouponView: {
      sportMobileWrap: {
        background: background1,
      },
    },
    SportTabs: {
      activeTab: {
        color: black,
        '&:hover, &:focus, &.active': {
          color: black,
        },
      },
      tabs: {
        backgroundColor: background1,
      },
    },
    AllLeagues: {
      root: { backgroundColor: background1 },
    },
    SuspendedBlock: {
      suspendedBlock: {
        background: 'rgba(255, 255, 255, 0.2)',
        color: '#fff',
      },
    },
    Market: {
      root: {
        padding: [margins.xs, 0],
        borderRadius: 3,
        borderTop: 'none',
        background: background1,
      },
      date: {
        color: text4,
      },
    },
    WidgetSport: {
      event: {
        margin: [margins.md, 0],
        background: background1,
      },
    },
    OutcomesCounter: {
      outcomeCount: {
        color: '#fff',
        fontWeight: 'bold',
        '&:hover': {
          color: primary,
        },
      },
    },
    EventCoupon: {
      eventHeader: {},
    },
    Betbuilder: {
      toggler: {
        '&:hover': {
          background: primary,
        },
      },
      exampleContainer: {
        background: black,
      },
    },
  };
};
