// @flow
import { type JSSThemeDefaultFormType } from '../JSSThemeDefaultForm';

export const JSSThemeForm61000: JSSThemeDefaultFormType = themeVars => {
  const {
    colors: {
      colorTypes: { border2, background4, white, black },
    },
    borderRadius,
  } = themeVars;
  return {
    Input: {
      root: {
        borderColor: 'transparent',
        color: border2,
        background: white,
        '&::placeholder': {
          color: border2,
        },
        '&:-webkit-autofill': {
          '-webkit-box-shadow': `0 0 0 30px ${white} inset !important`,
          borderColor: border2,
          '-webkit-text-fill-color': `${border2} !important`,
        },
      },
      betslipInput: {
        height: '30px',
        lineHeight: '30px',
        border: 'none',
        borderRadius: borderRadius.md,
        background: white,
        '&::placeholder': {
          color: background4,
        },
      },
    },
    PasswordField: {
      iconShowPassword: {
        color: black,
      },
    },
    Field: {
      checkboxField: {
        margin: ['30px', 0, 0],
        position: 'relative',
      },
    },
  };
};
