// @flow
import type { JSSThemeVariablesType } from '../JSSThemeDefault';

export const JSSThemeRegistration61000 = (themeVars: JSSThemeVariablesType) => {
  const {
    colors: {
      colorTypes: { accent, black },
    },
  } = themeVars;
  return {
    RegistrationPageButton: {
      root: {
        background: accent,
        color: black,
        '&:hover': {
          color: black,
        },
      },
    },
  };
};
