// import { type JSSThemeDefaultFooterType } from '../JSSThemeDefaultFooter';
// @flow
import { type JSSThemeDefaultFooterAllType } from '../JSSThemeDefaultFooterAll';

export const JSSThemeFooterAll61000: JSSThemeDefaultFooterAllType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { white },
    },
  } = themeVars;
  return {
    licenseLink: {
      paddingRight: 0,
      '&:last-child': {
        display: 'none',
      },
    },
    newStyle: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      borderBottom: '1px solid #4d4d4d',
      paddingBottom: 20,
      '@media screen and (max-width: 1300px)': {
        flexWrap: 'wrap',
      },
    },
    logosContainer: {
      display: 'block',
      margin: 10,
    },
    logoSvg: {
      fill: white,
      width: 89,
      maxHeight: 30,
    },
    logoSection: {
      width: '100%',
    },
    menuWrapper: {
      paddingBottom: 0,
    },
  };
};
