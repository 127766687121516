// @flow
import { type JSSThemeDefaultLanguageSwitcherType } from '../JSSThemeDefaultLanguageSwitcher';

export const JSSThemeLanguageSwitcher61000: JSSThemeDefaultLanguageSwitcherType = themeVars => {
  const {
    colors: {
      colorTypes: {  },
    },
  } = themeVars;
  return {
    LanguageSelectComponent: {
      languageTitle: {
        textTransform: 'capitalize'
      },
    },
  };
};
