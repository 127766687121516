// @flow
import { type JSSThemeDefaultHeaderMobileType } from '../JSSThemeDefaultHeaderMobile';

export const JSSThemeHeaderMobile61000: JSSThemeDefaultHeaderMobileType = themeVars => {
  const {
    colors: {
      colorTypes: { black, white },
    },
  } = themeVars;
  return {
    HeaderMobile: {
      headerContainer: {
        background: black,
      },
      menuContainer: {
        background: black,
        color: white,
      },
      logoImg: {
        maxWidth: '100px',
        width: 'fit-content',
      },
    },
  };
};
