// @flow
import type { JSSThemeDefaultResetsType } from '../JSSThemeDefaultResets';
import { getButtonColors } from '../jss-helpers';

export const JSSTheme61000Resets: JSSThemeDefaultResetsType = themeVars => {
  const {
    colors: {
      colorCombinations: { whiteNeutral1 },
    },
  } = themeVars;
  return {
    button: {
      lineHeight: '26px',
      ...getButtonColors(whiteNeutral1),
    },
  };
};
