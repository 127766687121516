// @flow
import { type JSSThemeDefaultOutcomesType } from '../JSSThemeDefaultOutcomes';

export const JSSThemeOutcomes61000: JSSThemeDefaultOutcomesType = themeVars => {
  const {
    colors: {
      colorTypes: { neutral1, text1, white, primary, black },
    },
  } = themeVars;
  return {
    MobileOutcome: {
      root: {
        width: 50,
        background: '#5A5A5A',
        '&.active': {
          background: primary,
          color: black,
        },
      },
      wideRoot: {
        background: neutral1,
        border: `1px solid ${text1}`,
      },
      priceValue: {
        fontSize: 10,
        color: white,
      },
    },
    Outcome: {
      root: {
        background: '#5A5A5A',
        height: '28px',
        lineHeight: '28px',
        '&.active': {
          background: primary,
          color: black,
        },
        '@media not all and (pointer: coarse)': {
          '&:hover': {
            background: primary,
            color: black,
          },
        },
      },
    },
  };
};
